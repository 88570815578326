<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-05-25 23:03:37
 * @LastEditTime: 2021-05-27 15:25:04
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="talent-concept">
    <SonTitle title="人才理念"></SonTitle>
    <div>
      {{ data }}
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: ''
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.$api.getConcept().then(res => {
        this.data = res.data.data.content
      })
    }
  },
}
</script>

<style scoped lang='scss'>
</style>